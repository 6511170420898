import {getField, updateField} from "vuex-map-fields";
import {api} from "../../../api";
import { offices } from "../../../store/modules/appic/constants"

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		LocalSalesInvoice: {
			currency_id: 1,
			gstrmrate: 1,
			headertaxschedule: 'SR-0',
			item_list_version: 1.1,
			id_doc_vars: {
				travel_document: {
					number: null,
					truck_number: null,
					driver: null,
					forwarding: 'TRUCK-KEP'
				},
				company_note: {}
			},
			json: {
				delivery_terms: null,
			},
			ms_id: 1,
			office_id: 1,
			Customer: {
				ls_paymentstatement_id: null
			},
			SalesOrder: {
				id: null,
				title: null
			}
		}
	}
}

const state = {
	current: {
		LocalSalesInvoice: {
			currency_id: 1,
			gstrmrate: 1,
			headertaxschedule: 'SR-0',
			item_list_version: 1.0,
			id_doc_vars: {
				travel_document: {
					number: null,
					truck_number: null,
					driver: null,
					forwarding: 'TRUCK-KEP'
				},
				company_note: {}
			},
			json: {
				delivery_terms: null,
			},
			ms_id: 1,
			office_id: 1,
			Customer: {
				ls_paymentstatement_id: null
			},
			SalesOrder: {
				id: null,
				title: null
			}
		},
		LocalSalesInvoices: [],
		localSalesInvoiceBuyers: [],
		totalAmounts: [],
		totalLocalSalesInvoices: 0,
	},
	update: {
		LocalSalesInvoice: {
			currency_id: 1,
			gstrmrate: 1,
			headertaxschedule: 'SR-0',
			item_list_version: 1.1,
			id_doc_vars: {
				travel_document: {
					number: null,
					truck_number: null,
					driver: null,
					forwarding: 'TRUCK-KEP'
				},
				company_note: {}
			},
			json: {
				delivery_terms: null,
			},
			ms_id: 1,
			office_id: 1,
			Customer: {
				ls_paymentstatement_id: null
			},
			SalesOrder: {
				id: null,
				title: null
			}
		}
	},
	defaultPaymentStatementId: 4304, //TT before release
	newLocalSalesInvoiceSaved: false,
	newLocalSalesInvoiceUnsaved: false,
	updatedLocalSalesInvoiceUnsaved: false,
	updatedLocalSalesInvoiceSaved: false
}

const getters = {
	getField,
	localSalesInvoiceBuyers: state => { return state.current.localSalesInvoiceBuyers },
}

const mutations = {
	resetCurrentState(state) {
		console.log('Resetting this invoice...')
		state.current.LocalSalesInvoice = getDefaultState().LocalSalesInvoice
		state.newLocalSalesInvoiceUnsaved = false
		state.newLocalSalesInvoiceSaved = false
	},
	resetState (state){
		console.log('Resetting invoices...')
		state.allActiveInvoices = []
	},
	setCurrentLocalSalesInvoice ( state, payload ) {
		let salesOrder = payload
		state.current.LocalSalesInvoice = _.cloneDeep(salesOrder.SalesOrder)
		//unset some properties
		state.current.LocalSalesInvoice.id = null
		state.current.LocalSalesInvoice.title = null
		state.current.LocalSalesInvoice.localsaledate = null
		//parse some json values
		state.current.LocalSalesInvoice.json = JSON.parse(salesOrder.SalesOrder.json)
		state.current.LocalSalesInvoice.SalesOrder = {
			id: salesOrder.SalesOrder.id,
			title: salesOrder.SalesOrder.title
		}
	},
	setLocalSalesInvoices ( state, data ) {
		state.current.LocalSalesInvoices = data;
	},
	setLocalSalesInvoiceBuyers (state,data) {
		state.current.localSalesInvoiceBuyers = data;
	},
	setLocalSalesInvoiceToBeUpdated ( state, data ) {
		state.update.LocalSalesInvoice = data.LocalSalesInvoice

		//parse some json values
		state.update.LocalSalesInvoice.json = JSON.parse(data.LocalSalesInvoice.json)

		state.update.LocalSalesInvoice.SalesOrder = {
			id: data.LocalSalesInvoice.SalesOrder.id,
			title: data.LocalSalesInvoice.SalesOrder.title
		}
	},
	setTotalLocalSalesAmounts ( state, val ) {
		state.current.totalAmounts = val
	},
	setTotalLocalSalesInvoices ( state, val ) {
		state.current.totalLocalSalesInvoices = val
	},
	updateField,
	updateIndonesiaDocuments (state, payload) {
		const keys = payload['field'].split('.')
		const prefix = payload['prefix']
		state[prefix].LocalSalesInvoice['id_doc_vars'][keys[0]][keys[1]] = payload['value']
	}
}

const actions = {
	cancelLocalSalesInvoiceById ({commit}, val) {
		return new Promise((resolve, reject) => {
			api
				.delete("/localsales/" + val)
				.then(response => {
					// if(response.data.status == 'success') {
					// 	resolve('done')
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				})
				// .finally(() => {
				// 	resolve('error')
				// })
		})
	},
	createLocalSalesInvoice ({state, rootState}) {
		let localSalesInvoice = _.cloneDeep(state.current.LocalSalesInvoice)
		let salesOrder = _.cloneDeep(state.current.LocalSalesInvoice.SalesOrder)

		//remove unneeded properties
		delete localSalesInvoice.Bank
		delete localSalesInvoice.Contact
		delete localSalesInvoice.Company
		delete localSalesInvoice.Customer
		delete localSalesInvoice.Invoice
		delete localSalesInvoice.Office
		delete localSalesInvoice.Paymentstatement
		delete localSalesInvoice.SalesOrder
		delete localSalesInvoice.Salescontact
		delete localSalesInvoice.Status

		return new Promise((resolve, reject) => {
			api
				.post('/localsales',{
						localsale: localSalesInvoice,
						localsaleitems: rootState.appic.localsalesitem.current.LocalSalesInvoiceItems,
						salesorder: salesOrder
					}
				)
				.then((response) => {
					// if(response.data.status == 'success') {
					// 	resolve(response.data)
					// } else {
					// 	resolve('error')
					// }
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .finally(()=>{
				// 	resolve('error')
				// })
		})
	},
	getAllActiveLocalSalesInvoices ( {commit} ) {
		let dt = new Date()
		dt.setDate(dt.getDate() - 365);
		return new Promise((resolve, reject) => {
			api
				.get('/localsales/active',{
					params: {
						conditions: [
							{
								field: 'localsales.localsaledate',
								value: dt.toISOString().substr(0,10),
								greaterThanOrEqual: true
							}
						]
					}
				})
				.then( response => {
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getAllLocalSalesInvoices ( {commit}, payload ) {
		// let filterOptions = payload.filterOptions
		// let conditions = []
		// if(filterOptions.year){
		// 	let obj = {
		// 		field: 'localsales.year',
		// 		value: filterOptions.year
		// 	}
		// 	conditions.push(obj)
		// }
		// if(filterOptions.month){
		// 	let obj = {
		// 		field: 'localsales.month',
		// 		value: filterOptions.month
		// 	}
		// 	conditions.push(obj)
		// }
		// if(filterOptions.buyer?.Customer?.id){
		// 	let obj = {
		// 		field: 'localsales.customer_id',
		// 		value: filterOptions.buyer.Customer.id
		// 	}
		// 	conditions.push(obj)
		// }
		// if(filterOptions.marketing?.Salesperson?.id){
		// 	let obj = {
		// 		field: 'localsales.salescontact_id',
		// 		value: filterOptions.marketing.Salesperson.id
		// 	}
		// 	conditions.push(obj)
		// }
		// if(filterOptions.office?.Office?.id){
		// 	let obj = {
		// 		field: 'offices.id',
		// 		value: filterOptions.office.Office.id
		// 	}
		// 	conditions.push(obj)
		// }
		return new Promise((resolve, reject) => {
			if(payload.tableOptions.page != null) {
				api
					.get("/localsales", {
						params: {
							// conditions: conditions,
							pagination: {
								skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
								take: payload.tableOptions.itemsPerPage
							},
							order: [
								{
									field: 'localsales.localsaledate',
									direction: 'DESC'
								},
								{
									field: 'localsales.title',
									direction: 'ASC'
								},
							]
						}
					})
					.then(response => {
						commit('setLocalSalesInvoices', response.data.data)
						commit('setTotalLocalSalesInvoices', response.data.totalRows)
						commit('setTotalLocalSalesAmounts', response.data.totalAmounts)
						resolve('done')
					})
					.catch(error => {
						reject(error);
					});
			} else {
				resolve('done')
			}
		})
	},
	getLocalSalesInvoiceToUpdateById ( {commit}, localsale_id ){
		return new Promise((resolve, reject) => {
			api
				.get('/localsales/' + localsale_id)
				.then(response => {
					commit('setLocalSalesInvoiceToBeUpdated', response.data.data[0]);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	getAllLocalSalesInvoiceBuyers ( {commit} ) {
		return new Promise((resolve, reject) => {
			api
				.get("/localsales/buyers",{
				})
				.then(response => {
					commit('setLocalSalesInvoiceBuyers',response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	loadUnInvoicedSalesOrder ( { commit },payload ) {
		return new Promise((resolve, reject) => {
			commit('setCurrentLocalSalesInvoice', payload)
			resolve('done')
		})
	},
	postLocalSalesInvoiceById ( {commit} ) {

	},
	resetAllLocalSalesInvoices ( {commit} ) {
		return new Promise ((resolve, reject) => {
			resolve('done')
		})
	},
	resetCurrentLocalSalesInvoice ( {commit} ) {
		return new Promise ((resolve, reject) => {
			commit('resetCurrentState')
			resolve('done')
		})
	},
	searchAllLocalSalesInvoices ( {commit}, payload ) {
		// let conditions = []
		// let obj = {
		// 	field: payload.search.field,
		// 	value: payload.search.value
		// }
		// conditions.push(obj)
		return new Promise((resolve, reject) => {
			api
				.get("/localsales",{
					params: {
						conditions: payload.conditions,
						pagination: {
							skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
							take: payload.tableOptions.itemsPerPage
							// skip: (payload.tableOptions.page - 1) * -1,
							// take: -1
						},
						order: payload.order
						// order: [
						// 	{
						// 		field: 'localsales.title',
						// 		direction: 'ASC'
						// 	},
						// 	{
						// 		field: 'localsales.localsaledate',
						// 		direction: 'ASC'
						// 	},
						// ]
					}
				})
				.then(response => {
					commit('setLocalSalesInvoices', response.data.data)
					commit('setTotalLocalSalesInvoices', response.data.totalRows)
					commit('setTotalLocalSalesAmounts', response.data.totalAmounts)
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.then(()=>{
					resolve('done')
				});
		})
	},
	updateLocalSalesInvoice ( {state, rootState} ) {
		let localSalesInvoice = _.cloneDeep(state.update.LocalSalesInvoice)
		//TODO remove unneeded properties
		delete localSalesInvoice.Bank
		delete localSalesInvoice.Company
		delete localSalesInvoice.Contact
		delete localSalesInvoice.Currency
		delete localSalesInvoice.Customer
		delete localSalesInvoice.Invoice
		delete localSalesInvoice.Office
		delete localSalesInvoice.Payment
		delete localSalesInvoice.Paymentstatement
		delete localSalesInvoice.Paymentterm
		delete localSalesInvoice.Salescontact
		delete localSalesInvoice.Status
		delete localSalesInvoice.Total

		return new Promise((resolve, reject) => {
			api
				.put('/localsales/' + state.update.LocalSalesInvoice.id,{
						localsale: localSalesInvoice,
						localsaleitems: rootState.appic.localsalesitem.update.LocalSalesInvoiceItems
					}
				)
				.then((response)=>{
					this.updatedLocalSalesInvoiceSaved = true
					this.updatedLocalSalesInvoiceUnsaved = false
					// resolve('done')
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
				// .finally(()=>{
				// 	resolve('done')
				// })
		})
	}
}

export const localsalesinvoice = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}