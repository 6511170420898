export const demo_menus = {
    sales: {
        'message.general': [
            {
                id: 1,
                action: 'appic-dashboard',
                active: true,
                icon: 'mdi-monitor-dashboard',
                items: null,
                path: '/dashboard/standard',
                title: 'message.myDashboard',
                ability: {
                    action: 'access',
                    subject: 'DemoSales'
                }
            },
            {
                id: 2,
                action: 'appic-reports',
                active: false,
                icon: 'mdi-file-chart-outline',
                items: null,
                path: '/reports/appic-report',
                title: 'message.appicReport',
                ability: {
                    action: 'access',
                    subject: 'AppicReport'
                }
            },
            {
                id: 3,
                action: 'marketing-report',
                active: false,
                icon: 'mdi-chart-bar',
                items: null,
                path: '/reports/marketing-report',
                title: 'message.marketingReport',
                ability: {
                    action: 'access',
                    subject: 'MarketingReport'
                }
            },
            {
                id: 4,
                action: 'buyer-locator',
                active: false,
                icon: 'mdi-map-search-outline',
                items: null,
                path: '/customers/map',
                title: 'message.buyerLocator',
                ability: {
                    action: 'access',
                    subject: 'BuyerLocations'
                }
            },
            {
                id: 5,
                action: 'contract-requests',
                active: false,
                icon: 'mdi-text-box-multiple-outline',
                items: null,
                path: '/ncrs/overview',
                title: 'message.contractRequests',
                ability: {
                    action: 'access',
                    subject: 'Ncrs'
                }
            },
            {
                id: 6,
                action: 'new-contract-request',
                active: false,
                icon: 'mdi-text-box-plus-outline',
                items: null,
                path: '/ncrs/add',
                title: 'message.newContractRequest',
                ability: {
                    action: 'create',
                    subject: 'Ncr'
                }
            },
            {
                id: 7,
                action: 'product-search',
                active: false,
                icon: 'mdi-briefcase-search-outline',
                items: null,
                path: '/suppliers/product-search',
                title: 'message.productSearch',
                ability: {
                    action: 'access',
                    subject: 'ExportProducts'
                }
            },
            {
                id: 8,
                action: 'buyer-lifecyle',
                active: false,
                icon: 'mdi-sync',
                items: null,
                path: '/reports/customer-life-cycle',
                title: 'message.buyerLifeCycle',
                ability: {
                    action: 'access',
                    subject: 'BuyerLifeCycle'
                }
            },
            {
                id: 9,
                action: 'demo-selector',
                active: false,
                icon: 'mdi-swap-horizontal',
                path: '/session/demo-selector',
                title: 'message.demoSelector',
                items: null,
                ability: {
                    action: 'access',
                    subject: 'Demo'
                }
            }
        ]
    },
    operations: {
        'message.general': [
            {
                id: 1,
                action: 'appic-dashboard',
                active: true,
                icon: 'mdi-monitor-dashboard',
                items: null,
                path: '/dashboard/standard',
                title: 'message.myDashboard',
                ability: {
                    action: 'access',
                    subject: 'Demo'
                }
            },
            {
                id: 2,
                action: 'appic-reports',
                active: false,
                icon: 'mdi-file-chart-outline',
                items: null,
                path: '/reports/appic-report',
                title: 'message.appicReport',
                ability: {
                    action: 'access',
                    subject: 'AppicReport'
                }
            },
            {
                id: 3,
                icon: 'mdi-finance',
                action: 'appic-sales-marketing',
                title: 'message.salesAndMarketing',
                active: false,
                items: [
                    {
                        id: 301,
                        title: 'message.marketingReport',
                        path: '/v1/reports/marketing-report',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'MarketingReport'
                        }
                    },
                    {
                        id: 302,
                        title: 'message.buyerSupplierLocator',
                        path: '/v1/customers/map',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'BuyerLocations'
                        }
                    },
                    {
                        id: 303,
                        title: 'message.contractRequests',
                        path: '/v1/ncrs/overview',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'Ncrs'
                        }
                    },
                    {
                        id: 304,
                        title: 'message.newContractRequest',
                        path: '/v1/ncrs/add',
                        exact: true,
                        ability: {
                            action: 'create',
                            subject: 'Ncr'
                        }
                    },
                    {
                        id: 305,
                        title: 'message.productSearch',
                        path: '/v1/suppliers/product-search',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'ExportProducts'
                        }
                    },
                    {
                        id: 306,
                        title: 'message.buyerLifeCycle',
                        path: '/v1/reports/customer-life-cycle',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'BuyerLifeCycle'
                        }
                    },
                    {
                        id: 307,
                        title: 'message.hubSpotDeals',
                        path: '/v1/reports/hubspot-deals',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'HubSpotDeals'
                        }
                    }
                ],
                ability: {
                    action: 'access',
                    subject: 'SalesMarketing'
                }
            },
            {
                id: 4,
                action: 'appic-operations',
                active: false,
                icon: 'mdi-cog-sync-outline',
                title: 'message.operations',
                items: [
                    {
                        id: 401,
                        title: 'message.contracts',
                        path: '/v1/contracts/overview',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'Contracts'
                        }
                    },
                    {
                        id: 402,
                        title: 'message.addContract',
                        path: '/v1/contracts/add',
                        exact: true,
                        ability: {
                            action: 'create',
                            subject: 'Contract'
                        }
                    },
                    {
                        id: 403,
                        title: 'message.pos',
                        path: '/v1/purchaseorders/overview',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'Pos'
                        }
                    },
                    {
                        id: 404,
                        title: 'message.addPO',
                        path: '/v1/purchaseorders/add',
                        exact: true,
                        ability: {
                            action: 'create',
                            subject: 'Po'
                        }
                    },
                    {
                        id: 405,
                        title: 'message.contractRequests',
                        path: '/v1/ncrs/overview',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'Ncrs'
                        }
                    },
                    {
                        id: 406,
                        title: 'message.newContractRequest',
                        path: '/v1/ncrs/add',
                        exact: true,
                        ability: {
                            action: 'create',
                            subject: 'Ncr'
                        }
                    },
                    {
                        id: 407,
                        title: 'message.fscPefc',
                        path: '/v1/reports/fsc-pefc-demo',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'FscPefcReport'
                        }
                    }
                ],
                ability: {
                    action: 'access',
                    subject: 'Operations'
                }
            },
            {
                id: 5,
                action: 'appic-data-mgt',
                icon: 'mdi-database-sync-outline',
                title: 'message.dataManagement',
                active: false,
                items: [
                    {
                        id: 501,
                        title: 'message.buyerProfiles',
                        path: '/v1/customers',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'Buyers'
                        }
                    },
                    {
                        id: 502,
                        title: 'message.addBuyer',
                        path: '/v1/customers/add',
                        exact: true,
                        ability: {
                            action: 'create',
                            subject: 'Buyer'
                        }
                    },
                    {
                        id: 503,
                        title: 'message.supplierProfiles',
                        path: '/v1/suppliers',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'Suppliers'
                        }
                    },
                    {
                        id: 504,
                        title: 'message.addSupplier',
                        path: '/v1/suppliers/add',
                        exact: true,
                        ability: {
                            action: 'create',
                            subject: 'Supplier'
                        }
                    },
                    {
                        id: 505,
                        title: 'message.operationsData',
                        path: '/v1/operations-demo-data',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'OperationsData'
                        }
                    },
                    {
                        id: 506,
                        title: 'message.specificationsData',
                        path: '/v1/specifications-demo-data',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'SpecificationsData'
                        }
                    }
                ],
                ability: {
                    action: 'access',
                    subject: 'DataManagement'
                }
            },
            {
                id: 6,
                action: 'appic-demo-shipments',
                active: false,
                icon: 'mdi-ferry',
                items: null,
                title: 'message.shipments',
                path: '/shipments/demo-overview',
                ability: {
                    action: 'access',
                    subject: 'Shipments'
                }
            },
            {
                id: 7,
                action: 'appic-invoicing',
                icon: 'mdi-cash-usd-outline',
                title: 'message.invoicing',
                active: false,
                items: [
                    {
                        id: 701,
                        title: 'message.invoices',
                        path: '/v1/invoices/overview',
                        exact: true,
                        ability: {
                            action: 'access',
                            subject: 'Invoices'
                        }
                    },
                    {
                        id: 702,
                        title: 'message.addInvoice',
                        path: '/v1/invoices/add',
                        exact: true,
                        ability: {
                            action: 'create',
                            subject: 'Invoice'
                        }
                    }
                ],
                ability: {
                    action: 'access',
                    subject: 'Finance'
                }
            },
            {
                id: 8,
                action: 'appic-user-mgt',
                active: false,
                icon: 'mdi-account-group-outline',
                items: null,
                path: '/users/overview',
                title: 'message.userManagement',
                ability: {
                    action: 'access',
                    subject: 'Users'
                }
            },
            {
                id: 9,
                action: 'appic-help',
                active: false,
                icon: 'help_outline',
                path: '/help',
                title: 'message.help',
                items: null,
                ability: {
                    action: 'access',
                    subject: 'HelpPages'
                }
            }
        ]
    }
}